export interface PartnersManagementState {
  selectedResultPerPage: Record<string, string>;
  searchValue: string;
}

export type SortingStateType = {
  totalApps: null | boolean;
};

export enum PartnersManagementInputProperty {
  SearchValue = 'searchValue',
}

export enum PartnersManagementSelectProperties {
  SelectedResultPerPage = 'selectedResultPerPage',
}

export enum SortingStateProperties {
  TotalApps = 'totalApps',
}

export interface PartnerDataRowInterface {
  id: number;
  name: string;
  is_independent: number;
  email: string;
  total_apps: number;
}
