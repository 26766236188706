import { reactive, watch, ref, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import dayjs from 'dayjs';
import {
  PartnersManagementState,
  SortingStateType,
  PartnersManagementInputProperty,
  PartnersManagementSelectProperties,
  SortingStateProperties,
  PartnerDataRowInterface,
} from '../../../types/administration/partners-management/partners-management.type';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import { filterPartners } from '@/domains/pd-admin/api/administration/partners-management/partners-managemnet';
dayjs.extend(customParseFormat);

const usePartnersManagement = () => {
  const partnersManagementState = reactive<PartnersManagementState>({
    selectedResultPerPage: {
      label: '10',
      value: '10',
    },
    searchValue: '',
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    searchValue: {},
  };

  const RESULT_PER_PAGE = [];

  for (let i = 10; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const sortingState = reactive<SortingStateType>({
    totalApps: null,
  });

  const originalPartners = reactive<Record<string, PartnerDataRowInterface[]>>({
    list: [],
  });

  const filteredPartners = reactive<Record<string, PartnerDataRowInterface[]>>({
    list: [],
  });

  const displayedPartners = reactive<Record<string, PartnerDataRowInterface[]>>({
    list: [],
  });

  const currentFilterRange = reactive<any>({
    startIndex: -1,
    endIndex: -1,
  });

  const isFilterBySearch = ref(false);

  const isFilterApplied = ref(false);

  const isLoading = ref(false);

  const v$ = useVuelidate(rules, partnersManagementState);

  const onInputDataUpdate = (keyUp: KeyboardEvent, dataProperty: PartnersManagementInputProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    v$.value[dataProperty].$touch();
    partnersManagementState[dataProperty] = target.value;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      fetchResultsBySearch(target.value);
    }
  };

  const fetchResultsBySearch = async (searchValue: string) => {
    isLoading.value = true;

    const filterRes = await filterPartners(searchValue);
    if (!filterRes.partners) {
      isLoading.value = false;
      return;
    }
    originalPartners.list = [...filterRes.partners];
    filteredPartners.list = [...filterRes.partners];
    const initialTableRecordCount = filterRes.partners.length >= 10 ? 10 : filterRes.partners.length;
    displayedPartners.list = filterRes.partners.slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;
    isFilterBySearch.value = true;
    isLoading.value = false;
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: PartnersManagementSelectProperties,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    partnersManagementState[property].label = selectedValue.label;
    partnersManagementState[property].value = selectedValue.value;
  };

  watch(
    () => filteredPartners,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  watch([isFilterBySearch], ([newFilterBySearch]) => {
    isFilterApplied.value = newFilterBySearch;
    applyPageRangeFilter();
  });

  watch(
    () => partnersManagementState.selectedResultPerPage,
    (newCountOfResultPerPage) => {
      if (originalPartners.list.length >= Number(newCountOfResultPerPage.value)) {
        displayedPartners.list = !isFilterApplied.value
          ? originalPartners.list.slice(0, Number(newCountOfResultPerPage.value))
          : filteredPartners.list.slice(0, Number(newCountOfResultPerPage.value));
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = Number(newCountOfResultPerPage.value);
      } else {
        displayedPartners.list = !isFilterApplied.value
          ? originalPartners.list.slice(0, originalPartners.list.length)
          : filteredPartners.list.slice(0, originalPartners.list.length);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = originalPartners.list.length;
      }
    },
    { deep: true },
  );

  const applyPageRangeFilter = () => {
    let newPartnersListEndIndex;

    if (!isFilterApplied.value) {
      newPartnersListEndIndex =
        originalPartners.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalPartners.list.length;
      displayedPartners.list = originalPartners.list.slice(currentFilterRange.startIndex, newPartnersListEndIndex);
    } else {
      newPartnersListEndIndex =
        filteredPartners.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredPartners.list.length;
      displayedPartners.list = filteredPartners.list.slice(currentFilterRange.startIndex, newPartnersListEndIndex);
    }
  };

  const onSort = (sortingProperty: SortingStateProperties) => {
    const isAscending = !sortingState[sortingProperty];

    const newPartnersListEndIndex =
      originalPartners.list.length >= currentFilterRange.endIndex
        ? currentFilterRange.endIndex
        : originalPartners.list.length;

    filteredPartners.list = [
      ...originalPartners.list.sort((a, b) => {
        const sortValue = isAscending ? b.total_apps - a.total_apps : a.total_apps - b.total_apps;
        return sortValue;
      }),
    ];

    displayedPartners.list = filteredPartners.list.slice(currentFilterRange.startIndex, newPartnersListEndIndex);

    sortingState.totalApps = isAscending;
    isFilterApplied.value = true;
    sortingState[sortingProperty] = isAscending;
  };

  const onPreviousClick = () => {
    currentFilterRange.startIndex -= Number(partnersManagementState.selectedResultPerPage.value);
    currentFilterRange.endIndex -= Number(partnersManagementState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const onNextClick = () => {
    currentFilterRange.startIndex += Number(partnersManagementState.selectedResultPerPage.value);
    currentFilterRange.endIndex += Number(partnersManagementState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const isNextButtonDisabled = computed(() => {
    if (isFilterApplied.value) {
      return currentFilterRange.endIndex >= filteredPartners.list.length;
    } else {
      return currentFilterRange.endIndex >= originalPartners.list.length;
    }
  });

  return {
    partnersManagementState,
    sortingState,
    resultPerPage,
    v$,
    originalPartners,
    filteredPartners,
    displayedPartners,
    currentFilterRange,
    isFilterApplied,
    isNextButtonDisabled,
    PartnersManagementInputProperty,
    PartnersManagementSelectProperties,
    SortingStateProperties,
    isLoading,
    onInputDataUpdate,
    onSelectionDataChange,
    onSort,
    onPreviousClick,
    onNextClick,
  };
};

export default usePartnersManagement;
