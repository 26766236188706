import { defineComponent, onBeforeMount } from '@vue/composition-api';
import router from '@/router';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidInput,
  ZidLoader,
  ZidButton,
  ZidPagination,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import ExcelIcon from '@/assets/icons/domains/partnership-admin/ExcelIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import usePartnersManagement from '../../../helpers/administration/partners-management/usePartnersManagement';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { getAllPartners } from '@/domains/pd-admin/api/administration/partners-management/partners-managemnet';
import { PartnerDataRowInterface } from '@/domains/pd-admin/types/administration/partners-management/partners-management.type';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidInput,
    ExcelIcon,
    SearchIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidPagination,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    const {
      partnersManagementState,
      sortingState,
      resultPerPage,
      v$,
      originalPartners,
      filteredPartners,
      displayedPartners,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      PartnersManagementInputProperty,
      PartnersManagementSelectProperties,
      SortingStateProperties,
      isLoading,
      onInputDataUpdate,
      onSelectionDataChange,
      onSort,
      onPreviousClick,
      onNextClick,
    } = usePartnersManagement();

    onBeforeMount(async () => {
      isLoading.value = true;

      const response = await getAllPartners();

      if (!response.partners || !response.partners.length) {
        isLoading.value = false;
        return;
      }

      originalPartners.list = response.partners;
      const initialTableRecordCount = response.partners.length >= 10 ? 10 : response.partners.length;
      displayedPartners.list = response.partners.slice(0, initialTableRecordCount);
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = initialTableRecordCount;
      isLoading.value = false;
    });

    const onEditClicked = (partner: PartnerDataRowInterface) => {
      router
        .push({
          name: RoutingRouteEnum.Admin_PartnerDetails,
          params: { id: `${partner.id}` },
        })
        .catch((err) => {
          /**/
        });
    };

    return {
      partnersManagementState,
      sortingState,
      resultPerPage,
      v$,
      originalPartners,
      filteredPartners,
      displayedPartners,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      PartnersManagementInputProperty,
      PartnersManagementSelectProperties,
      SortingStateProperties,
      isLoading,
      onInputDataUpdate,
      onSelectionDataChange,
      onSort,
      onPreviousClick,
      onNextClick,
      onEditClicked,
    };
  },
});
