import BaseModel from '@/api/models/BaseModel';
import {
  PartnerAppInfoInterface,
  PartnershipInfoInterface,
} from '@/domains/pd-admin/types/administration/partners-management/partner-details/partner-details.type';
import { PartnerRoleEnum } from '@/types/models/partner.type';

class PartnerDetailsResponseModel extends BaseModel {
  public payload: {
    partner: {
      id: number;
      name: string;
      email: string;
      role: null | PartnerRoleEnum;
      application_type: number;
      country: string | null;
      is_independent: number;
      email_status: string;
      join_zid_certificate: string | null;
      specific_store: string | null;
      tech_stack: string | null;
      company_name: string | null;
      mobile: string;
      looking_for_ideas: number | null;
      reasons_to_join: string;
      is_trusted: boolean;
    };
    partner_bank_account: {
      partner_name: string;
      bank_name: string;
      account_name: string;
      iban: string;
      swift_code: string;
      country: string;
      city: string;
    };
    contract: PartnershipInfoInterface;
    partner_apps: PartnerAppInfoInterface[];
  };
  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default PartnerDetailsResponseModel;
