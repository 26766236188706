import {
  AppIntentionsDropdownEnum,
  AppIntentionsDropdownValuesEnum,
  DeliveryAndStorageOptionsEnum,
  LookingForIdeasDropdownEnum,
  PartnerTypeDropdownEnum,
  PartnerTypeDropdownValuesEnum,
  PotentialCustomersOptionsEnum,
  PotentialCustomersValuesEnum,
  SubWorkFieldValuesEnum,
  TechnologySolutionsOptionsEnum,
  WorkFieldsEnum,
  WorkFieldValuesEnum,
} from '@/domains/pd-admin/types/administration/partners-management/partner-details/partner-details.type';

export const PARTNER_TYPES_LIST = [
  { label: PartnerTypeDropdownEnum.Independent, value: PartnerTypeDropdownValuesEnum.Independent },
  { label: PartnerTypeDropdownEnum.Company, value: PartnerTypeDropdownValuesEnum.Company },
];

export const APPLICATION_INTENTIONS_LIST = [
  { label: AppIntentionsDropdownEnum.AppMarket, value: AppIntentionsDropdownValuesEnum.AppMarket },
  {
    label: AppIntentionsDropdownEnum.EnterprisePrivateApp,
    value: AppIntentionsDropdownValuesEnum.EnterprisePrivateApp,
  },
  {
    label: AppIntentionsDropdownEnum.ThemeDeveloper,
    value: AppIntentionsDropdownValuesEnum.ThemeDeveloper,
  },
];

export const IS_LOOKING_FOR_IDEAS = [
  { label: LookingForIdeasDropdownEnum.Yes, value: true },
  { label: LookingForIdeasDropdownEnum.No, value: false },
];

export const WORK_FIELDS = [
  { label: WorkFieldsEnum.DeliveryOrStorage, value: WorkFieldValuesEnum.DeliveryOrStorage },
  { label: WorkFieldsEnum.TechnologySolutions, value: WorkFieldValuesEnum.TechnologySolutions },
];

export const WORK_FIELD_DELIVERY_AND_STORAGE = [
  { label: DeliveryAndStorageOptionsEnum.LastMileDelivery, value: SubWorkFieldValuesEnum.LastMileDelivery },
  {
    label: DeliveryAndStorageOptionsEnum.StorageAndOrderProcessing,
    value: SubWorkFieldValuesEnum.StorageAndOrderProcessing,
  },
  { label: DeliveryAndStorageOptionsEnum.All, value: SubWorkFieldValuesEnum.All },
];

export const WORK_FIELD_TECHNOLOGY_SOLUTIONS = [
  {
    label: TechnologySolutionsOptionsEnum.AccountingSoftwareProvider,
    value: SubWorkFieldValuesEnum.AccountingSoftwareProvider,
  },
  {
    label: TechnologySolutionsOptionsEnum.ResourceManagementSoftwareProvider,
    value: SubWorkFieldValuesEnum.ResourceManagementSoftwareProvider,
  },
  {
    label: TechnologySolutionsOptionsEnum.ChatCommunicationSolutionsProvider,
    value: SubWorkFieldValuesEnum.ChatCommunicationSolutionsProvider,
  },
  {
    label: TechnologySolutionsOptionsEnum.DedicatedApplicationsForZidMerchants,
    value: SubWorkFieldValuesEnum.DedicatedApplicationsForZidMerchants,
  },
  { label: TechnologySolutionsOptionsEnum.OtherSolutions, value: SubWorkFieldValuesEnum.OtherSolutions },
];

export const POTENTIAL_CUSTOMERS = [
  { label: PotentialCustomersOptionsEnum['0-50'], value: PotentialCustomersValuesEnum['0-50'] },
  { label: PotentialCustomersOptionsEnum['51-100'], value: PotentialCustomersValuesEnum['51-100'] },
  { label: PotentialCustomersOptionsEnum['101-500'], value: PotentialCustomersValuesEnum['101-500'] },
  { label: PotentialCustomersOptionsEnum.MoreThan500, value: PotentialCustomersValuesEnum.MoreThan500 },
];
