import httpClient from '@/api/httpClient';
import PartnershipResponseModel from '@/domains/partnership-details/models/PartnershipResponseModel';
import BankAccountInfoModel from '@/domains/pd-admin/models/administration/partners-management/partner-details/bank-info-section-model';
import PartnerDetailsResponseModel from '@/domains/pd-admin/models/administration/partners-management/partner-details/partner-details-response-model';
import PartnerInfoModel from '@/domains/pd-admin/models/administration/partners-management/partner-details/partner-info-section-model';
import PartnershipInfoModel from '@/domains/pd-admin/models/administration/partners-management/partner-details/partnership-info-section-model';
import UpdatePartnerInfoResponseModel from '@/domains/pd-admin/models/administration/partners-management/partner-details/update-partner-info-section-response-model';
import { PartnershipInfoProps } from '@/domains/pd-admin/types/administration/partners-management/partner-details/partner-details.type';

const ENDPOINT_URL = '/market';

export const getPartnerDetails = (partnerID: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/admin/partner/${partnerID}`)
    .then((response) => new PartnerDetailsResponseModel(response))
    .catch((error) => error);
};

export const updatePartnerDetails = (partnerID: number, partnerInfo: PartnerInfoModel) => {
  return httpClient
    .put(`${ENDPOINT_URL}/partner/${partnerID}`, partnerInfo)
    .then((response) => new UpdatePartnerInfoResponseModel(response))
    .catch((error) => error);
};

export const updatePartnershipDetails = (partnershipInfo: PartnershipInfoModel) => {
  const partnershipData = new FormData();

  if (partnershipInfo.id) partnershipData.append(PartnershipInfoProps.Id, partnershipInfo.id);
  partnershipData.append(PartnershipInfoProps.Partnership_officer_name, partnershipInfo.partnership_officer_name);
  partnershipData.append(PartnershipInfoProps.Company_website, partnershipInfo.company_website);
  partnershipData.append(PartnershipInfoProps.Company_name, partnershipInfo.company_name);
  partnershipData.append(PartnershipInfoProps.Company_work_field_type, `${partnershipInfo.company_work_field_type}`);
  partnershipData.append(PartnershipInfoProps.Company_work_field, `${partnershipInfo.company_work_field}`);
  partnershipData.append(PartnershipInfoProps.Customer_count, `${partnershipInfo.customer_count}`);
  partnershipData.append(PartnershipInfoProps.Language, partnershipInfo.language ?? 'en');
  partnershipData.append(PartnershipInfoProps.Partner_id, `${partnershipInfo.partner_id}`);
  partnershipData.append(PartnershipInfoProps.Partnership_officer_email, partnershipInfo.partnership_officer_email);
  partnershipData.append(PartnershipInfoProps.Partnership_officer_mobile, partnershipInfo.partnership_officer_mobile);
  partnershipData.append(PartnershipInfoProps.Commercial_register_doc, partnershipInfo.commercial_register_doc);
  partnershipData.append(PartnershipInfoProps.Company_profile_doc, partnershipInfo.company_profile_doc ?? '');
  partnershipData.append(PartnershipInfoProps.Vat_certificate_doc, partnershipInfo.vat_certificate_doc ?? '');
  partnershipData.append(PartnershipInfoProps.Service_pricing_doc, partnershipInfo.service_pricing_doc ?? '');
  partnershipData.append(
    PartnershipInfoProps.Communications_license_doc,
    partnershipInfo.communications_license_doc ?? '',
  );

  return httpClient
    .post(`${ENDPOINT_URL}/partnership-details`, partnershipData)
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const updateBankInformation = (bankAccountInfo: BankAccountInfoModel) => {
  return httpClient
    .post(`${ENDPOINT_URL}/bank-accounts/save`, bankAccountInfo)
    .then((response) => response)
    .catch((error) => error);
};
