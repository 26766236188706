import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { RoutingRouteEnum } from '@/router/routes.enum';
import router from '@/router';
import {
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidInput,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidButton,
  ZidTooltip,
  ZidLoader,
  ZidCheckbox,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import usePartnerDetails from '../../../../helpers/administration/partners-management/partner-details/usePartnerDetails';
import PartnerInfoModel from '../../../../models/administration/partners-management/partner-details/partner-info-section-model';
import PartnershipInfoModel from '../../../../models/administration/partners-management/partner-details/partnership-info-section-model';
import BankAccountInfoModel from '../../../../models/administration/partners-management/partner-details/bank-info-section-model';
import {
  AppIntentionsDropdownValuesEnum,
  PartnerTypeDropdownValuesEnum,
} from '../../../../types/administration/partners-management/partner-details/partner-details.type';
import {
  updateBankInformation,
  updatePartnerDetails,
  updatePartnershipDetails,
} from '../../../../api/administration/partners-management/partner-details/partner-details';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import {
  ApplicationListingStatusProperty,
  ApplicationTypeProperty,
} from '../../../../../../api/models/ApplicationModel';

export default defineComponent({
  components: {
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
    ZidInput,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidButton,
    ZidTooltip,
    BaseIcon,
    EditIcon,
    ZidLoader,
    ZidCheckbox,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup(_, ctx) {
    const partnerID = Number(ctx.root.$route.params.id);

    const {
      isLoadingData,
      initialPartnerData,
      v$,
      partnerDetailsState,
      currentFilterRange,
      countriesList,
      partnerTypes,
      applicationIntentions,
      isLookingForIdeasOptions,
      PartnerDetailsInputPropertiesEnum,
      PartnerDetailsSelectPropertiesEnum,
      isSavePartnerDataBtnDisabled,
      isSavePartnershipDataBtnDisabled,
      isSaveBankDataBtnDisabled,
      workFields,
      workFieldLabel,
      workFields_DeliveryAndStorage,
      workFieldDeliveryAndStorageLabel,
      workFields_TechnologySolutions,
      workFieldTechnologySolutionsLabel,
      potentialCustomersCount,
      potentialCustomersLabel,
      workFieldTooltipContent,
      FilesPropertiesEnum,
      WorkFieldsEnum,
      partnershipDetails,
      isSubmittingPartnerData,
      isSubmittingPartnershipData,
      isSubmittingBankData,
      partnershipInfoExists,
      companyProfileLink,
      commercialRegisterLink,
      VATCertificateLink,
      servicesWithSuggestedPricingLink,
      communicationsAndInformationTechnologyLink,
      partnerApps,
      onInputDataUpdate,
      onSelectionDataChange,
      potentialCustomersDropdown,
      workFieldTechnologySolutionsDropdown,
      workFieldDeliveryAndStorageDropdown,
      workFieldDropdown,
      extractFileOrFileState,
      populateDataFromResponse,
      onIsTrustedChange,
    } = usePartnerDetails(partnerID);

    const onUpdatePartnerData = async () => {
      isSubmittingPartnerData.value = true;
      const partnerInfo = new PartnerInfoModel({
        name: partnerDetailsState.fullName,
        email: partnerDetailsState.emailAddress,
        mobile: partnerDetailsState.partnerMobile,
        country: partnerDetailsState.country.value,
        is_independent: partnerDetailsState.partnerType.value === PartnerTypeDropdownValuesEnum.Independent ? '1' : '0',
        application_type:
          partnerDetailsState.appIntendedFor.value === AppIntentionsDropdownValuesEnum.AppMarket ? '1' : '0',
        looking_for_ideas:
          partnerDetailsState.isLookingForIdea.value || partnerDetailsState.isLookingForIdea.value === 'true'
            ? '1'
            : '0',
        is_trusted: partnerDetailsState.isTrusted ? '1' : '0',
      });

      if (!isNaN(partnerID)) {
        const response = await updatePartnerDetails(partnerID, partnerInfo);
        if (!response.partner) {
          Vue.$toast.error('Failed to save changes!');
          return;
        }
        Vue.$toast.success('Changes have been saved successfully!');
      }

      isSubmittingPartnerData.value = false;
    };

    const onSubmitPartnershipData = async () => {
      let workFieldSubSelectData = null;
      isSubmittingPartnershipData.value = true;
      if (partnerDetailsState.workField.label === WorkFieldsEnum.DeliveryOrStorage) {
        workFieldSubSelectData = partnerDetailsState.workField_DeliveryAndStorage.value;
      } else if (partnerDetailsState.workField.label === WorkFieldsEnum.TechnologySolutions) {
        workFieldSubSelectData = partnerDetailsState.workField_TechnologySolutions.value;
      }

      const partnershipData = new PartnershipInfoModel({
        id: initialPartnerData.data.payload.contract.id,
        partnership_officer_name: partnerDetailsState.partnershipOfficerName,
        company_website: partnerDetailsState.companyWebsite,
        company_name: partnerDetailsState.companyName,
        company_work_field_type: partnerDetailsState.workField.value,
        company_work_field: workFieldSubSelectData,
        customer_count: partnerDetailsState.potentialCustomers.value,
        language: initialPartnerData.data.payload.contract.language,
        partner_id: partnerID,
        partnership_officer_email: partnerDetailsState.partnerEmail,
        partnership_officer_mobile: partnerDetailsState.mobileNumber,
        company_profile_doc: extractFileOrFileState(FilesPropertiesEnum.companyProfile),
        commercial_register_doc: extractFileOrFileState(FilesPropertiesEnum.commercialRegister),
        vat_certificate_doc: extractFileOrFileState(FilesPropertiesEnum.VATCertificate),
        service_pricing_doc: extractFileOrFileState(FilesPropertiesEnum.servicesWithSuggestedPricing),
        communications_license_doc: extractFileOrFileState(FilesPropertiesEnum.communicationsAndInformationTechnology),
      });

      const response = await updatePartnershipDetails(partnershipData);

      if (!response.contract) {
        Vue.$toast.error(`Failed to update partnership information!`);
        isSubmittingPartnershipData.value = false;
        return;
      }

      populateDataFromResponse(response.contract);
      Vue.$toast.success(`Partnership information have been updated`);

      partnershipInfoExists.value = true;
      isSubmittingPartnershipData.value = false;
    };

    const onUpdateBankInformation = async () => {
      isSubmittingBankData.value = true;
      const bankAccountInfo = new BankAccountInfoModel({
        partner_id: partnerID,
        bank_name: partnerDetailsState.bankName,
        account_name: partnerDetailsState.accountName,
        iban: partnerDetailsState.ibanNumber,
        swift_code: partnerDetailsState.swiftCode,
        city: partnerDetailsState.city,
        country: partnerDetailsState.bankCountry.value,
      });

      const response = await updateBankInformation(bankAccountInfo);

      if (response.status !== 'success') {
        Vue.$toast.error('Failed to save bank account information changes');
        isSubmittingBankData.value = false;
        return;
      }
      Vue.$toast.success('Bank account information has been updated successfully');
      isSubmittingBankData.value = false;
    };

    const onEditAppClicked = (application) => {
      if (application.type === ApplicationTypeProperty.OAuth) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_OAuth,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      } else if (application.type === ApplicationTypeProperty.Javascript) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_JS,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      }
    };

    return {
      isLoadingData,
      v$,
      RoutingRouteEnum,
      partnerDetailsState,
      initialPartnerData,
      currentFilterRange,
      countriesList,
      partnerTypes,
      applicationIntentions,
      isLookingForIdeasOptions,
      PartnerDetailsInputPropertiesEnum,
      PartnerDetailsSelectPropertiesEnum,
      isSavePartnerDataBtnDisabled,
      isSavePartnershipDataBtnDisabled,
      isSaveBankDataBtnDisabled,
      isSubmittingPartnerData,
      isSubmittingPartnershipData,
      isSubmittingBankData,
      workFields,
      workFieldLabel,
      workFields_DeliveryAndStorage,
      workFieldDeliveryAndStorageLabel,
      workFields_TechnologySolutions,
      workFieldTechnologySolutionsLabel,
      potentialCustomersCount,
      potentialCustomersLabel,
      workFieldTooltipContent,
      FilesPropertiesEnum,
      WorkFieldsEnum,
      partnershipDetails,
      partnershipInfoExists,
      companyProfileLink,
      commercialRegisterLink,
      VATCertificateLink,
      servicesWithSuggestedPricingLink,
      communicationsAndInformationTechnologyLink,
      partnerApps,
      ApplicationListingStatusProperty,
      ApplicationTypeProperty,
      onInputDataUpdate,
      onSelectionDataChange,
      onUpdatePartnerData,
      onSubmitPartnershipData,
      potentialCustomersDropdown,
      workFieldTechnologySolutionsDropdown,
      workFieldDeliveryAndStorageDropdown,
      workFieldDropdown,
      onUpdateBankInformation,
      onEditAppClicked,
      onIsTrustedChange,
    };
  },
});
