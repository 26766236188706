import BaseModel from '@/api/models/BaseModel';
import { PartnerDataRowInterface } from '../../../types/administration/partners-management/partners-management.type';

class PartnersManagementResponseModel extends BaseModel {
  public partners: PartnerDataRowInterface[];
  constructor(arg: any) {
    super(arg);
    this.partners = arg.partners;
  }
}

export default PartnersManagementResponseModel;
