import BaseModel from '@/api/models/BaseModel';
import { appTypePropertyEnum } from '@/domains/profile/types/profile.type';
import { PartnerRoleEnum } from '@/types/models/partner.type';

class UpdatePartnerInfoResponseModel extends BaseModel {
  public partner: {
    id: number;
    name: string;
    email: string;
    role: null | PartnerRoleEnum;
    application_type: appTypePropertyEnum;
    country: string;
    looking_for_ideas: number;
    is_independent: number;
    email_status: string;
    join_zid_certificate: null | string;
    specific_store: null | string;
    tech_stack: null | string;
    reasons_to_join: string;
    company_name: null | string;
    mobile: string;
    created_at: string;
  };
  constructor(arg: any) {
    super(arg);
    this.partner = arg.partner;
  }
}

export default UpdatePartnerInfoResponseModel;
