import { ApplicationListingStatusProperty, ApplicationTypeProperty } from '@/api/models/ApplicationModel';

export interface PartnerDetailsStateInterface {
  // partner info
  fullName: string;
  emailAddress: string;
  partnerMobile: string;
  country: Record<string, string>;
  partnerType: Record<string, string>;
  appIntendedFor: Record<string, string>;
  isLookingForIdea: Record<string, string | boolean>;
  isTrusted: boolean;
  // partnership
  partnershipOfficerName: string;
  companyName: string;
  companyWebsite: string;
  partnerEmail: string;
  mobileNumber: string;
  workField: {
    label: string;
    value: string;
  };
  workField_DeliveryAndStorage: {
    label: string;
    value: string;
  };
  workField_TechnologySolutions: {
    label: string;
    value: string;
  };
  potentialCustomers: {
    label: string;
    value: string;
  };
  companyProfileFile?: File;
  commercialRegisterFile?: File;
  VATCertificateFile?: File;
  servicesWithSuggestedPricingFile?: File;
  communicationsAndInformationTechnologyFile?: File;
  // bank
  bankName: string;
  accountName: string;
  swiftCode: string;
  ibanNumber: string;
  bankCountry: {
    label: string;
    value: string;
  };
  city: string;
}

export enum PartnerDetailsSelectPropertiesEnum {
  // partner info
  Country = 'country',
  PartnerType = 'partnerType',
  AppIntendedFor = 'appIntendedFor',
  IsLookingForIdea = 'isLookingForIdea',
  // partnership
  WorkField = 'workField',
  workField_DeliveryAndStorage = 'workField_DeliveryAndStorage',
  workField_TechnologySolutions = 'workField_TechnologySolutions',
  potentialCustomers = 'potentialCustomers',
  // bank
  BankCountry = 'bankCountry',
}

export enum PartnerDetailsInputPropertiesEnum {
  // partner info
  FullName = 'fullName',
  EmailAddress = 'emailAddress',
  PartnerMobile = 'partnerMobile',
  // partnership
  PartnershipOfficerName = 'partnershipOfficerName',
  CompanyName = 'companyName',
  CompanyWebsite = 'companyWebsite',
  PartnerEmail = 'partnerEmail',
  MobileNumber = 'mobileNumber',
  // bank
  BankName = 'bankName',
  AccountName = 'accountName',
  SwiftCode = 'swiftCode',
  IbanNumber = 'ibanNumber',
  City = 'city',
}

export enum PartnerTypeDropdownEnum {
  Independent = 'An Independent Developer',
  Company = 'An Employed Developer (company)',
}

export enum PartnerTypeDropdownValuesEnum {
  Independent = 'independent',
  Company = 'employed',
}

export enum AppIntentionsDropdownEnum {
  AppMarket = 'Developing Public App (list on Zid App Market)',
  EnterprisePrivateApp = 'Developing Private App (only for Professional and Enterprise packages )',
  ThemeDeveloper = 'Developing Theme (list on Zid Theme Market)',
}

export enum AppIntentionsDropdownValuesEnum {
  AppMarket = 'zid-app-market',
  EnterprisePrivateApp = 'enterprise-merchant',
  ThemeDeveloper = 'theme-developer',
}

export enum LookingForIdeasDropdownEnum {
  Yes = 'Yes',
  No = 'No',
}

export interface PartnershipInfoInterface {
  id?: string;
  partnership_officer_name: string;
  company_website: string;
  company_name: string;
  company_work_field_type: number;
  company_work_field: number;
  customer_count: number;
  language: string;
  partner_id: number;
  partnership_officer_email: string;
  partnership_officer_mobile: string;
  company_profile_doc?: File | string;
  commercial_register_doc: File | string;
  vat_certificate_doc?: File | string;
  service_pricing_doc?: File | string;
  communications_license_doc?: File | string;
}

export interface PartnerAppInfoInterface {
  id: number;
  name: string;
  app_category_id: number;
  icon: string;
  zid_percentage: string;
  partner_name: string;
  type: ApplicationTypeProperty;
  type_label: string;
  installs_count: null | number;
  rate: number;
  status: ApplicationListingStatusProperty;
  status_label: string;
  price_model: string;
}

export enum WorkFieldsEnum {
  DeliveryOrStorage = 'Delivery/Storage Companies',
  TechnologySolutions = 'Technology Solutions Companies',
}

export enum WorkFieldValuesEnum {
  DeliveryOrStorage = '1',
  TechnologySolutions = '2',
}

export enum SubWorkFieldValuesEnum {
  LastMileDelivery = '1',
  StorageAndOrderProcessing = '2',
  All = '3',
  AccountingSoftwareProvider = '4',
  ResourceManagementSoftwareProvider = '5',
  ChatCommunicationSolutionsProvider = '6',
  DedicatedApplicationsForZidMerchants = '7',
  OtherSolutions = '8',
}

export enum DeliveryAndStorageOptionsEnum {
  LastMileDelivery = 'Last Mile Delivery Company',
  StorageAndOrderProcessing = 'Storage And Order Processing Company',
  All = 'All',
}

export enum TechnologySolutionsOptionsEnum {
  AccountingSoftwareProvider = 'Accounting Software Provider',
  ResourceManagementSoftwareProvider = 'Resource Management Software Provider',
  ChatCommunicationSolutionsProvider = 'Chat & Communication Solutions Provider',
  DedicatedApplicationsForZidMerchants = 'Dedicated Applications For Zid Merchants',
  OtherSolutions = 'Other Solutions',
}

export enum PotentialCustomersOptionsEnum {
  '0-50' = '0-50',
  '51-100' = '51-100',
  '101-500' = '101-500',
  MoreThan500 = 'More Than 500',
}
export enum PotentialCustomersValuesEnum {
  '0-50' = '50',
  '51-100' = '100',
  '101-500' = '500',
  MoreThan500 = '501',
}

export enum FilesPropertiesEnum {
  companyProfile = 'companyProfileFile',
  commercialRegister = 'commercialRegisterFile',
  VATCertificate = 'VATCertificateFile',
  servicesWithSuggestedPricing = 'servicesWithSuggestedPricingFile',
  communicationsAndInformationTechnology = 'communicationsAndInformationTechnologyFile',
}

export type PartnershipContractType = {
  id: number;
  partner_id: number;
  company_name: string;
  company_website: string;
  company_work_field_type: number;
  company_work_field: number;
  customer_count: number;
  language: string;
  partnership_officer_email: string;
  partnership_officer_mobile: string;
  partnership_officer_name: string;
  company_profile_doc: string;
  commercial_register_doc: string;
  vat_certificate_doc: string | null;
  service_pricing_doc: string;
  communications_license_doc: string | null;
  submission_date: string;
};

export enum PartnershipInfoProps {
  Id = 'id',
  Partnership_officer_name = 'partnership_officer_name',
  Company_website = 'company_website',
  Company_name = 'company_name',
  Company_work_field_type = 'company_work_field_type',
  Company_work_field = 'company_work_field',
  Customer_count = 'customer_count',
  Language = 'language',
  Partner_id = 'partner_id',
  Partnership_officer_email = 'partnership_officer_email',
  Partnership_officer_mobile = 'partnership_officer_mobile',
  Company_profile_doc = 'company_profile_doc',
  Commercial_register_doc = 'commercial_register_doc',
  Vat_certificate_doc = 'vat_certificate_doc',
  Service_pricing_doc = 'service_pricing_doc',
  Communications_license_doc = 'communications_license_doc',
}
