import httpClient from '@/api/httpClient';
import PartnersManagementResponseModel from '@/domains/pd-admin/models/administration/partners-management/partners-management-response-model';

const ENDPOINT_URL = '/market/admin/partners';

export const getAllPartners = () => {
  return httpClient
    .get(`${ENDPOINT_URL}`)
    .then((response) => new PartnersManagementResponseModel(response))
    .catch((error) => error);
};

export const filterPartners = (searchValue: string | null) => {
  const search = `${searchValue !== null && searchValue.trim() !== '' ? `search=${searchValue}` : ``}`;
  return httpClient
    .get(`${ENDPOINT_URL}?${search}`)
    .then((response) => new PartnersManagementResponseModel(response))
    .catch((error) => error);
};
