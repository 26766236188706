class PartnershipInfoModel {
  public id?: string;
  public partnership_officer_name: string;
  public company_website: string;
  public company_name: string;
  public company_work_field_type: number;
  public company_work_field: number;
  public customer_count: number;
  public language: string;
  public partner_id: number;
  public partnership_officer_email: string;
  public partnership_officer_mobile: string;
  public company_profile_doc?: File | string;
  public commercial_register_doc: File | string;
  public vat_certificate_doc?: File | string;
  public service_pricing_doc?: File | string;
  public communications_license_doc?: File | string;

  constructor(arg: any) {
    this.id = arg.id;
    this.partnership_officer_name = arg.partnership_officer_name;
    this.company_website = arg.company_website;
    this.company_name = arg.company_name;
    this.company_work_field_type = arg.company_work_field_type;
    this.company_work_field = arg.company_work_field;
    this.customer_count = arg.customer_count;
    this.language = arg.language;
    this.partner_id = arg.partner_id;
    this.partnership_officer_email = arg.partnership_officer_email;
    this.partnership_officer_mobile = arg.partnership_officer_mobile;
    this.company_profile_doc = arg.company_profile_doc;
    this.commercial_register_doc = arg.commercial_register_doc;
    this.vat_certificate_doc = arg.vat_certificate_doc;
    this.service_pricing_doc = arg.service_pricing_doc;
    this.communications_license_doc = arg.communications_license_doc;
  }
}

export default PartnershipInfoModel;
