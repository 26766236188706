import { reactive, onBeforeMount, ref, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import dayjs from 'dayjs';
import { COUNTRIES_LIST } from '@/helpers/constants';
import {
  APPLICATION_INTENTIONS_LIST,
  IS_LOOKING_FOR_IDEAS,
  PARTNER_TYPES_LIST,
  POTENTIAL_CUSTOMERS,
  WORK_FIELDS,
  WORK_FIELD_DELIVERY_AND_STORAGE,
  WORK_FIELD_TECHNOLOGY_SOLUTIONS,
} from './dropdownsInfo';
import { required, helpers, email, minLength, maxLength } from '@vuelidate/validators';
import {
  PartnerDetailsStateInterface,
  PartnerDetailsSelectPropertiesEnum,
  PartnerDetailsInputPropertiesEnum,
  PartnerTypeDropdownValuesEnum,
  PartnerTypeDropdownEnum,
  AppIntentionsDropdownEnum,
  AppIntentionsDropdownValuesEnum,
  LookingForIdeasDropdownEnum,
  WorkFieldsEnum,
  DeliveryAndStorageOptionsEnum,
  TechnologySolutionsOptionsEnum,
  PotentialCustomersOptionsEnum,
  FilesPropertiesEnum,
  PartnershipContractType,
  WorkFieldValuesEnum,
  SubWorkFieldValuesEnum,
  PotentialCustomersValuesEnum,
  PartnerAppInfoInterface,
} from '../../../../types/administration/partners-management/partner-details/partner-details.type';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getPartnerDetails } from '@/domains/pd-admin/api/administration/partners-management/partner-details/partner-details';
import PartnerDetailsResponseModel from '@/domains/pd-admin/models/administration/partners-management/partner-details/partner-details-response-model';
import { URL_REGEX } from '@/helpers/regex/URLs';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import { PHONE_NUMBER } from '@/helpers/regex/numbers';
import { ENGLISH_TEXT } from '@/helpers/regex/text';

dayjs.extend(customParseFormat);

const usePartnerDetails = (partnerID: number) => {
  const isLoadingData = ref(false);
  const isSubmittingPartnerData = ref(false);
  const isSubmittingPartnershipData = ref(false);
  const isSubmittingBankData = ref(false);
  const partnershipInfoExists = ref(true);
  const companyProfileLink = ref('');
  const commercialRegisterLink = ref('');
  const VATCertificateLink = ref('');
  const servicesWithSuggestedPricingLink = ref('');
  const communicationsAndInformationTechnologyLink = ref('');
  const partnershipDetails = reactive({ details: null });
  const initialPartnerData = reactive<Record<string, PartnerDetailsResponseModel>>({
    data: new PartnerDetailsResponseModel({}),
  });

  const partnerDetailsState = reactive<PartnerDetailsStateInterface>({
    // partner info
    fullName: '',
    emailAddress: '',
    partnerMobile: '',
    country: {
      label: '',
      value: '',
    },
    partnerType: {
      label: '',
      value: '',
    },
    appIntendedFor: {
      label: '',
      value: '',
    },
    isLookingForIdea: {
      label: '',
      value: '',
    },
    isTrusted: false,
    // partnership
    partnershipOfficerName: '',
    companyName: '',
    companyWebsite: '',
    partnerEmail: '',
    mobileNumber: '',
    workField: {
      label: '',
      value: '',
    },
    workField_DeliveryAndStorage: {
      label: '',
      value: '',
    },
    workField_TechnologySolutions: {
      label: '',
      value: '',
    },
    potentialCustomers: {
      label: '',
      value: '',
    },
    companyProfileFile: undefined,
    commercialRegisterFile: undefined,
    VATCertificateFile: undefined,
    servicesWithSuggestedPricingFile: undefined,
    communicationsAndInformationTechnologyFile: undefined,
    // bank
    bankName: '',
    accountName: '',
    swiftCode: '',
    ibanNumber: '',
    bankCountry: {
      label: '',
      value: '',
    },
    city: '',
  });

  const rules = {
    // partner info
    fullName: { fullName: helpers.withMessage('Full name is required', required) },
    emailAddress: { required, emailAddress: helpers.withMessage('E-mail address is not valid', email) },
    partnerMobile: {
      required,
      partnerMobile: helpers.withMessage('Mobile number is required', required),
      minLength: minLength(8),
      maxLength: maxLength(14),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    country: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    partnerType: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    appIntendedFor: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    isLookingForIdea: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    isTrusted: {},
    // partnership info
    partnershipOfficerName: {
      required,
    },
    companyName: {
      required,
    },
    companyWebsite: {
      required,
      companyWebsite: (value: string) => URL_REGEX.test(value),
    },
    partnerEmail: {
      required,
      email,
    },
    mobileNumber: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(15),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    workField: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    workField_DeliveryAndStorage: {
      label: {},
      value: {},
    },
    workField_TechnologySolutions: {
      label: {},
      value: {},
    },
    potentialCustomers: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    companyProfileFile: {},
    commercialRegisterFile: { required },
    VATCertificateFile: {},
    servicesWithSuggestedPricingFile: {},
    communicationsAndInformationTechnologyFile: {},
    // bank
    bankName: {
      bankName: helpers.withMessage('Bank name is required', required),
    },
    accountName: {
      required,
      accountName: helpers.withMessage('Account name is not valid', (value: string) => {
        return ENGLISH_TEXT.test(value);
      }),
    },
    swiftCode: {
      swiftCode: helpers.withMessage('Swift code is required', required),
    },
    ibanNumber: {
      ibanNumber: helpers.withMessage('IBAN number is required', required),
    },
    bankCountry: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    city: {
      required,
      city: helpers.withMessage('City is not valid', (value: string) => {
        return ENGLISH_TEXT.test(value);
      }),
    },
  };

  const v$ = useVuelidate(rules, partnerDetailsState);

  const countriesList = reactive({
    options: COUNTRIES_LIST,
  });

  const partnerTypes = reactive({
    options: PARTNER_TYPES_LIST,
  });

  const applicationIntentions = reactive({
    options: APPLICATION_INTENTIONS_LIST,
  });

  const isLookingForIdeasOptions = reactive({
    options: IS_LOOKING_FOR_IDEAS,
  });

  const workFields = reactive({
    options: WORK_FIELDS,
  });

  const workFields_DeliveryAndStorage = reactive({
    options: WORK_FIELD_DELIVERY_AND_STORAGE,
  });

  const workFields_TechnologySolutions = reactive({
    options: WORK_FIELD_TECHNOLOGY_SOLUTIONS,
  });

  const potentialCustomersCount = reactive({
    options: POTENTIAL_CUSTOMERS,
  });

  const currentFilterRange = reactive<any>({
    startIndex: -1,
    endIndex: -1,
  });

  const partnerApps = reactive<Record<string, PartnerAppInfoInterface[]>>({
    list: [],
  });

  onBeforeMount(async () => {
    isLoadingData.value = true;
    if (!isNaN(partnerID)) {
      const response = await getPartnerDetails(partnerID);
      if (!response.payload) {
        isLoadingData.value = false;
        return;
      }

      initialPartnerData.data = new PartnerDetailsResponseModel(response);
      isLoadingData.value = false;

      // partner information
      if (response.payload.partner) {
        partnerDetailsState.fullName = response.payload.partner.name ?? '';
        partnerDetailsState.emailAddress = response.payload.partner.email ?? '';
        partnerDetailsState.partnerMobile = response.payload.partner.mobile ?? '';

        partnerDetailsState.country = {
          label: response.payload.partner.country
            ? convertFirstLetterToUppercase(response.payload.partner.country)
            : '',
          value: response.payload.partner.country ?? '',
        };

        partnerDetailsState.partnerType = extractPartnerTypeSelection(response.payload.partner.is_independent);
        partnerDetailsState.appIntendedFor = extractAppIntendedForSelection(response.payload.partner.application_type);
        partnerDetailsState.isLookingForIdea = extractLookingForIdeaSelection(
          response.payload.partner.looking_for_ideas,
        );
        partnerDetailsState.isTrusted = response.payload.partner.is_trusted;
      }
      // partner information

      // partnership information
      if (response.payload.contract) {
        populateDataFromResponse(response.payload.contract);
      }
      // partnership information

      // bank
      if (response.payload.partner_bank_account) {
        partnerDetailsState.bankName = response.payload.partner_bank_account.bank_name || '';
        partnerDetailsState.accountName = response.payload.partner_bank_account.account_name || '';
        partnerDetailsState.swiftCode = response.payload.partner_bank_account.swift_code || '';
        partnerDetailsState.ibanNumber = response.payload.partner_bank_account.iban || '';
        if (response.payload.partner_bank_account.country) {
          partnerDetailsState.bankCountry = {
            label:
              `${response.payload.partner_bank_account?.country
                .charAt(0)
                .toUpperCase()}${response.payload.partner_bank_account.country?.slice(1)}` || '',
            value: response.payload.partner_bank_account?.country || '',
          };
        } else {
          partnerDetailsState.bankCountry = {
            label: '',
            value: '',
          };
        }
        partnerDetailsState.city = response.payload.partner_bank_account.city || '';
      }
      // bank

      // partner apps
      if (response.payload.partner_apps) {
        partnerApps.list = [...response.payload.partner_apps];
      }
      // partner apps
    }

    isLoadingData.value = false;
  });

  const onInputDataUpdate = (changedValue: Record<string, string>, dataProperty: PartnerDetailsInputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    partnerDetailsState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: PartnerDetailsSelectPropertiesEnum,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    partnerDetailsState[property].label = selectedValue.label;
    partnerDetailsState[property].value = selectedValue.value;
  };

  const onIsTrustedChange = (changedValue: Record<string, boolean>) => {
    v$.value.isTrusted.$touch();
    partnerDetailsState.isTrusted = changedValue.isChecked;
  };

  const workFieldDropdown = (value: string) => {
    if (!value) return '';
    if (value === WorkFieldsEnum.DeliveryOrStorage) {
      return WorkFieldsEnum.DeliveryOrStorage;
    } else return WorkFieldsEnum.TechnologySolutions;
  };

  const workFieldLabel = computed(() => workFieldDropdown(partnerDetailsState.workField.label));

  const workFieldDeliveryAndStorageDropdown = (value: string) => {
    if (!value) return '';
    switch (value) {
      case DeliveryAndStorageOptionsEnum.LastMileDelivery:
        return DeliveryAndStorageOptionsEnum.LastMileDelivery;
      case DeliveryAndStorageOptionsEnum.StorageAndOrderProcessing:
        return DeliveryAndStorageOptionsEnum.StorageAndOrderProcessing;
      case DeliveryAndStorageOptionsEnum.All:
        return DeliveryAndStorageOptionsEnum.All;
      default:
        return '';
    }
  };

  const workFieldDeliveryAndStorageLabel = computed(() =>
    workFieldDeliveryAndStorageDropdown(partnerDetailsState.workField_DeliveryAndStorage.label),
  );

  const workFieldTechnologySolutionsDropdown = (value: string) => {
    if (!value) return '';
    switch (value) {
      case TechnologySolutionsOptionsEnum.AccountingSoftwareProvider:
        return TechnologySolutionsOptionsEnum.AccountingSoftwareProvider;
      case TechnologySolutionsOptionsEnum.ResourceManagementSoftwareProvider:
        return TechnologySolutionsOptionsEnum.ResourceManagementSoftwareProvider;
      case TechnologySolutionsOptionsEnum.ChatCommunicationSolutionsProvider:
        return TechnologySolutionsOptionsEnum.ChatCommunicationSolutionsProvider;
      case TechnologySolutionsOptionsEnum.DedicatedApplicationsForZidMerchants:
        return TechnologySolutionsOptionsEnum.DedicatedApplicationsForZidMerchants;
      case TechnologySolutionsOptionsEnum.OtherSolutions:
        return TechnologySolutionsOptionsEnum.OtherSolutions;
      default:
        return '';
    }
  };

  const workFieldTechnologySolutionsLabel = computed(() =>
    workFieldTechnologySolutionsDropdown(partnerDetailsState.workField_TechnologySolutions.label),
  );

  const potentialCustomersDropdown = (value: string) => {
    if (!value) return '';
    switch (value) {
      case PotentialCustomersOptionsEnum.MoreThan500:
        return PotentialCustomersOptionsEnum.MoreThan500;
      default:
        return value;
    }
  };

  const potentialCustomersLabel = computed(() =>
    potentialCustomersDropdown(partnerDetailsState.potentialCustomers.label),
  );

  const workFieldTooltipContent = computed(() => {
    switch (partnerDetailsState.workField.label) {
      case WorkFieldsEnum.DeliveryOrStorage:
        return 'Fixed amount on order + fixed amount service activation fee';
      case WorkFieldsEnum.TechnologySolutions:
        return 'Zid will collect the amounts through the subscription system in the Zid App Market';
      default:
        return 'Please, select work field';
    }
  });

  const convertFirstLetterToUppercase = (string: string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

  const extractPartnerTypeSelection = (value: number) => {
    if (value === 1)
      return { label: PartnerTypeDropdownEnum.Independent, value: PartnerTypeDropdownValuesEnum.Independent };
    else return { label: PartnerTypeDropdownEnum.Company, value: PartnerTypeDropdownValuesEnum.Company };
  };

  const extractAppIntendedForSelection = (value: number) => {
    if (value === 1)
      return { label: AppIntentionsDropdownEnum.AppMarket, value: AppIntentionsDropdownValuesEnum.AppMarket };
    else if (value === 2)
      return { label: AppIntentionsDropdownEnum.ThemeDeveloper, value: AppIntentionsDropdownValuesEnum.ThemeDeveloper };
    else
      return {
        label: AppIntentionsDropdownEnum.EnterprisePrivateApp,
        value: AppIntentionsDropdownValuesEnum.EnterprisePrivateApp,
      };
  };

  const extractLookingForIdeaSelection = (value: number) => {
    if (value === 1) return { label: LookingForIdeasDropdownEnum.Yes, value: true };
    else return { label: LookingForIdeasDropdownEnum.No, value: false };
  };

  const populateDataFromResponse = async (partnershipData: PartnershipContractType) => {
    if (partnershipData !== null && !(partnershipData instanceof Array)) {
      partnerDetailsState.partnershipOfficerName = partnershipData.partnership_officer_name;
      partnerDetailsState.partnerEmail = partnershipData.partnership_officer_email;
      partnerDetailsState.mobileNumber = partnershipData.partnership_officer_mobile;
      partnerDetailsState.companyName = partnershipData.company_name;
      partnerDetailsState.companyWebsite = partnershipData.company_website;
      switch (partnershipData.company_work_field_type) {
        case Number(WorkFieldValuesEnum.DeliveryOrStorage):
          partnerDetailsState.workField.label = WorkFieldsEnum.DeliveryOrStorage;
          partnerDetailsState.workField.value = WorkFieldValuesEnum.DeliveryOrStorage;
          switch (partnershipData.company_work_field) {
            case Number(SubWorkFieldValuesEnum.LastMileDelivery):
              partnerDetailsState.workField_DeliveryAndStorage.label = DeliveryAndStorageOptionsEnum.LastMileDelivery;
              partnerDetailsState.workField_DeliveryAndStorage.value = SubWorkFieldValuesEnum.LastMileDelivery;
              break;
            case Number(SubWorkFieldValuesEnum.StorageAndOrderProcessing):
              partnerDetailsState.workField_DeliveryAndStorage.label =
                DeliveryAndStorageOptionsEnum.StorageAndOrderProcessing;
              partnerDetailsState.workField_DeliveryAndStorage.value = SubWorkFieldValuesEnum.StorageAndOrderProcessing;
              break;
            case Number(SubWorkFieldValuesEnum.All):
              partnerDetailsState.workField_DeliveryAndStorage.label = DeliveryAndStorageOptionsEnum.All;
              partnerDetailsState.workField_DeliveryAndStorage.value = SubWorkFieldValuesEnum.All;
              break;
            default:
              break;
          }
          break;
        case Number(WorkFieldValuesEnum.TechnologySolutions):
          partnerDetailsState.workField.label = WorkFieldsEnum.TechnologySolutions;
          partnerDetailsState.workField.value = WorkFieldValuesEnum.TechnologySolutions;
          switch (partnershipData.company_work_field) {
            case Number(SubWorkFieldValuesEnum.AccountingSoftwareProvider):
              partnerDetailsState.workField_TechnologySolutions.label =
                TechnologySolutionsOptionsEnum.AccountingSoftwareProvider;
              partnerDetailsState.workField_TechnologySolutions.value =
                SubWorkFieldValuesEnum.AccountingSoftwareProvider;
              break;
            case Number(SubWorkFieldValuesEnum.ResourceManagementSoftwareProvider):
              partnerDetailsState.workField_TechnologySolutions.label =
                TechnologySolutionsOptionsEnum.ResourceManagementSoftwareProvider;
              partnerDetailsState.workField_TechnologySolutions.value =
                SubWorkFieldValuesEnum.ResourceManagementSoftwareProvider;
              break;
            case Number(SubWorkFieldValuesEnum.ChatCommunicationSolutionsProvider):
              partnerDetailsState.workField_TechnologySolutions.label =
                TechnologySolutionsOptionsEnum.ChatCommunicationSolutionsProvider;
              partnerDetailsState.workField_TechnologySolutions.value =
                SubWorkFieldValuesEnum.ChatCommunicationSolutionsProvider;
              break;
            case Number(SubWorkFieldValuesEnum.DedicatedApplicationsForZidMerchants):
              partnerDetailsState.workField_TechnologySolutions.label =
                TechnologySolutionsOptionsEnum.DedicatedApplicationsForZidMerchants;
              partnerDetailsState.workField_TechnologySolutions.value =
                SubWorkFieldValuesEnum.DedicatedApplicationsForZidMerchants;
              break;
            case Number(SubWorkFieldValuesEnum.OtherSolutions):
              partnerDetailsState.workField_TechnologySolutions.label = TechnologySolutionsOptionsEnum.OtherSolutions;
              partnerDetailsState.workField_TechnologySolutions.value = SubWorkFieldValuesEnum.OtherSolutions;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      switch (partnershipData.customer_count) {
        case Number(PotentialCustomersValuesEnum['0-50']):
          partnerDetailsState.potentialCustomers.label = PotentialCustomersOptionsEnum['0-50'];
          partnerDetailsState.potentialCustomers.value = PotentialCustomersValuesEnum['0-50'];
          break;
        case Number(PotentialCustomersValuesEnum['51-100']):
          partnerDetailsState.potentialCustomers.label = PotentialCustomersOptionsEnum['51-100'];
          partnerDetailsState.potentialCustomers.value = PotentialCustomersValuesEnum['51-100'];
          break;
        case Number(PotentialCustomersValuesEnum['101-500']):
          partnerDetailsState.potentialCustomers.label = PotentialCustomersOptionsEnum['101-500'];
          partnerDetailsState.potentialCustomers.value = PotentialCustomersValuesEnum['101-500'];
          break;
        case Number(PotentialCustomersValuesEnum.MoreThan500):
          partnerDetailsState.potentialCustomers.label = PotentialCustomersOptionsEnum.MoreThan500;
          partnerDetailsState.potentialCustomers.value = PotentialCustomersValuesEnum.MoreThan500;
          break;
        default:
          break;
      }

      companyProfileLink.value = partnershipData.company_profile_doc ?? '';
      commercialRegisterLink.value = partnershipData.commercial_register_doc;
      VATCertificateLink.value = partnershipData.vat_certificate_doc ?? '';
      servicesWithSuggestedPricingLink.value = partnershipData.service_pricing_doc ?? '';
      if (partnerDetailsState.workField.label === WorkFieldsEnum.TechnologySolutions)
        communicationsAndInformationTechnologyLink.value = partnershipData.communications_license_doc ?? '';

      if (partnershipData.company_profile_doc) {
        const companyFile = await getFileFromURL(
          partnershipData.company_profile_doc,
          FilesPropertiesEnum.companyProfile,
          'image/png, application/pdf',
        );
        partnerDetailsState.companyProfileFile = companyFile;
      }

      if (partnershipData.commercial_register_doc) {
        const commercialRegisterFile = await getFileFromURL(
          partnershipData.commercial_register_doc,
          FilesPropertiesEnum.commercialRegister,
          'image/png, application/pdf',
        );
        partnerDetailsState.commercialRegisterFile = commercialRegisterFile;
      }

      if (partnershipData.vat_certificate_doc) {
        const VATCertificateFile = await getFileFromURL(
          partnershipData.vat_certificate_doc,
          FilesPropertiesEnum.VATCertificate,
          'image/png, application/pdf',
        );
        partnerDetailsState.commercialRegisterFile = VATCertificateFile;
      }

      if (partnershipData.service_pricing_doc) {
        const servicePricingFile = await getFileFromURL(
          partnershipData.service_pricing_doc,
          FilesPropertiesEnum.servicesWithSuggestedPricing,
          'image/png, application/pdf',
        );
        partnerDetailsState.commercialRegisterFile = servicePricingFile;
      }

      if (partnershipData.communications_license_doc) {
        const communicationLicenseFile = await getFileFromURL(
          partnershipData.communications_license_doc,
          FilesPropertiesEnum.communicationsAndInformationTechnology,
          'image/png, application/pdf',
        );
        partnerDetailsState.commercialRegisterFile = communicationLicenseFile;
      }
      partnershipInfoExists.value = true;
    }
  };

  const extractFileOrFileState = (fileProperty: FilesPropertiesEnum) => {
    switch (fileProperty) {
      case FilesPropertiesEnum.companyProfile:
        if (partnerDetailsState.companyProfileFile) return partnerDetailsState.companyProfileFile;
        else return '';
      case FilesPropertiesEnum.commercialRegister:
        if (partnerDetailsState.commercialRegisterFile) return partnerDetailsState.commercialRegisterFile;
        else return '';
      case FilesPropertiesEnum.VATCertificate:
        if (partnerDetailsState.VATCertificateFile) return partnerDetailsState.VATCertificateFile;
        else return '';
      case FilesPropertiesEnum.servicesWithSuggestedPricing:
        if (partnerDetailsState.servicesWithSuggestedPricingFile)
          return partnerDetailsState.servicesWithSuggestedPricingFile;
        else return '';
      case FilesPropertiesEnum.communicationsAndInformationTechnology:
        if (partnerDetailsState.communicationsAndInformationTechnologyFile)
          return partnerDetailsState.communicationsAndInformationTechnologyFile;
        else return '';
      default:
        break;
    }
  };

  const isSavePartnerDataBtnDisabled = computed(() => {
    return (
      v$.value.fullName.$invalid ||
      v$.value.emailAddress.$invalid ||
      v$.value.partnerMobile.$invalid ||
      v$.value.country.$invalid ||
      v$.value.partnerType.$invalid ||
      v$.value.appIntendedFor.$invalid ||
      v$.value.isLookingForIdea.$invalid
    );
  });

  const isSavePartnershipDataBtnDisabled = computed(() => {
    let isInvalid =
      v$.value.partnershipOfficerName.$invalid ||
      v$.value.partnerEmail.$invalid ||
      v$.value.mobileNumber.$invalid ||
      v$.value.companyName.$invalid ||
      v$.value.companyWebsite.$invalid ||
      v$.value.workField.$invalid ||
      v$.value.potentialCustomers.$invalid;

    if (partnerDetailsState.workField.label === WorkFieldsEnum.DeliveryOrStorage) {
      isInvalid = isInvalid || v$.value.workField_DeliveryAndStorage.$invalid;
    } else if (partnerDetailsState.workField.label === WorkFieldsEnum.TechnologySolutions) {
      isInvalid = isInvalid || v$.value.workField_TechnologySolutions.$invalid;
    }

    switch (partnerDetailsState.workField.label) {
      case WorkFieldsEnum.DeliveryOrStorage:
        return isInvalid || partnerDetailsState.workField_DeliveryAndStorage.value === '';
      case WorkFieldsEnum.TechnologySolutions:
        return isInvalid || partnerDetailsState.workField_TechnologySolutions.value === '';
      default:
        return true;
    }
  });

  const isSaveBankDataBtnDisabled = computed(() => {
    return (
      v$.value.bankName.$invalid ||
      v$.value.accountName.$invalid ||
      v$.value.swiftCode.$invalid ||
      v$.value.ibanNumber.$invalid ||
      v$.value.bankCountry.$invalid ||
      v$.value.city.$invalid
    );
  });

  return {
    isLoadingData,
    initialPartnerData,
    v$,
    partnerDetailsState,
    currentFilterRange,
    countriesList,
    partnerTypes,
    applicationIntentions,
    isLookingForIdeasOptions,
    PartnerDetailsInputPropertiesEnum,
    PartnerDetailsSelectPropertiesEnum,
    isSavePartnerDataBtnDisabled,
    isSavePartnershipDataBtnDisabled,
    isSaveBankDataBtnDisabled,
    workFields,
    workFieldLabel,
    workFields_DeliveryAndStorage,
    workFieldDeliveryAndStorageLabel,
    workFields_TechnologySolutions,
    workFieldTechnologySolutionsLabel,
    potentialCustomersCount,
    potentialCustomersLabel,
    workFieldTooltipContent,
    FilesPropertiesEnum,
    WorkFieldsEnum,
    partnershipDetails,
    isSubmittingPartnerData,
    isSubmittingPartnershipData,
    isSubmittingBankData,
    partnershipInfoExists,
    companyProfileLink,
    commercialRegisterLink,
    VATCertificateLink,
    servicesWithSuggestedPricingLink,
    communicationsAndInformationTechnologyLink,
    partnerApps,
    onInputDataUpdate,
    onSelectionDataChange,
    potentialCustomersDropdown,
    workFieldTechnologySolutionsDropdown,
    workFieldDeliveryAndStorageDropdown,
    workFieldDropdown,
    extractFileOrFileState,
    populateDataFromResponse,
    onIsTrustedChange,
  };
};

export default usePartnerDetails;
